<template>
  <div class="course-shop">
    <div class="nav">
      <el-tabs v-model="activeFirst" class="nav-first" @tab-click="handleFirstNavClick">
        <el-tab-pane label="全部" name="-1"></el-tab-pane>
        <el-tab-pane
          v-for="item in navList"
          :key="item.categoryId"
          :label="item.name"
          :name="item.categoryId + ''"
        ></el-tab-pane>
      </el-tabs>
      <el-tabs
        v-if="secondNavList.length"
        v-model="activeSecond"
        class="nav-second"
        @tab-click="handleSecondNavClick"
      >
        <el-tab-pane
          v-for="item in secondNavList"
          :key="item.categoryId"
          :label="item.name"
          :name="item.categoryId + ''"
        ></el-tab-pane>
      </el-tabs>
      <!-- <el-tabs
        v-if="thirdNavList.length"
        v-model="activeThird"
        class="nav-third"
        @tab-click="handleThirdNavClick"
      >
        <el-tab-pane
          v-for="item in thirdNavList"
          :key="item.categoryId"
          :label="item.name"
          :name="item.categoryId + ''"
        ></el-tab-pane>
      </el-tabs> -->
    </div>
    <div class="filter">
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          按名称排序<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item><div @click="handleNameOrder(2)">从高到低</div></el-dropdown-item>
          <el-dropdown-item><div @click="handleNameOrder(1)">从低到高</div></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          按授课数量排序<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item><div @click="handleQuantityOrder(4)">从高到低</div></el-dropdown-item>
          <el-dropdown-item><div @click="handleQuantityOrder(3)">从低到高</div></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-cascader
        v-model="city"
        :options="total"
        :props="optionProps"
        @change="handleChange"
      ></el-cascader>
      <div class="search">
        <el-input
          v-model="keyword"
          clearable
          maxlength="64"
          placeholder="请输入您想搜索的讲师名称"
          type="text"
        ></el-input>
        <div class="btn" @click="search">
          <i class="el-icon-search"></i>
        </div>
      </div>
    </div>
    <div class="content">
      <template v-if="teacherList.length">
        <div class="clearfix">
          <div
            v-for="item in teacherList"
            :key="item.teacherId"
            class="course-item"
            @click="toDetail(item)"
          >
            <div class="teacherWrap">
              <div class="cover" :style="`background-image:url(${item.teacherPic})`"></div>
              <div class="text">
                <div class="name">
                  <div class="teacherName">{{ item.teacherName }}</div>
                  <!-- <div class="teacherRankStr">{{ item.teacherRankStr }}</div> -->
                </div>
                <!-- <div class="info">{{ item.productCourseCount }}课</div> -->
                <div class="teacherMajors">
                  <span
                    v-for="(items, index) in item.teacherTags"
                    :key="index"
                    :style="{ color: `${items.color}`, background: `${items.background}` }"
                  >
                    {{ items.tagName }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-pagination
          background
          :current-page="pager.current"
          :page-sizes="[12, 24, 36, 48]"
          :page-size="pager.size"
          layout="total, prev, pager, next, sizes, jumper"
          :total="pager.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </template>
      <div v-else class="empty">暂无数据</div>
    </div>
  </div>
</template>
<script>
import total from '../../../assets/address/city'
import { teacherType, teacherNewList } from '@/api/teacher'
import to from 'await-to'
export default {
  name: 'CourseShop',
  data() {
    return {
      total,
      city: [],
      optionProps: {
        value: 'label',
        label: 'label',
        children: 'children',
      },
      navList: [],
      teacherMajors: [],
      secondNavList: [],
      thirdNavList: [],
      activeFirst: '-1',
      activeSecond: '',
      activeThird: '',
      categoryId: '',
      keyword: '',
      pager: {
        total: 0,
        size: 30,
        current: 1,
      },
      teacherList: [],
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == 'TeacherDetail') {
      from.meta.keepAlive = true
    } else {
      from.meta.keepAlive = false
    }
    next()
  },
  async mounted() {
    const { keyword, firstMajors, secondMajors } = this.$route.query
    this.keyword = keyword
    await this.getNavList()
    this.teacherMajors = secondMajors || firstMajors ? [secondMajors || firstMajors] : []
    this.activeFirst = firstMajors || '-1'
    if (firstMajors) {
      let item = this.navList.filter(item => item.categoryId == this.activeFirst)[0]
      this.secondNavList = item.children
    }
    if (secondMajors) {
      this.activeSecond = secondMajors
    }
    if (firstMajors) {
      let item = this.navList.filter(item => item.categoryId == this.activeFirst)[0]
      this.secondNavList = item.children
    }
    this.teacherNewListData(this.teacherMajors, '', this.keyword)
  },
  methods: {
    async getNavList() {
      const [res, err] = await to(teacherType({}))
      if (err) return this.$message.warning(err.msg)
      this.navList = res.data
    },
    async teacherNewListData(teacherMajors, sortFlag, teacherName, city) {
      const { current, size } = this.pager
      const [res, err] = await to(
        teacherNewList({
          current,
          size,
          teacherMajors,
          sortFlag,
          teacherName,
          province: city ? city[0] : '',
          city: city ? city[1] : '',
        }),
      )
      if (err) return this.$message.warning(err.msg)
      this.teacherList = res.data.list
      this.pager.total = res.data.total
    },
    handleChange(val) {
      this.city = val
      this.teacherNewListData([], '', '', val)
    },
    handleFirstNavClick(item) {
      this.teacherMajors = []
      this.activeSecond = ''
      this.activeThird = ''
      this.secondNavList = item.index == 0 ? [] : this.navList[item.index - 1].children
      this.thirdNavList = []
      this.categoryId = item.index == 0 ? '' : this.navList[item.index - 1].categoryId
      if (this.secondNavList.length > 0) {
        this.secondNavList.map(v => this.teacherMajors.push(v.categoryId))
      }
      this.teacherNewListData(this.teacherMajors)
    },
    handleSecondNavClick(item) {
      this.teacherMajors = []
      this.thirdNavList = this.secondNavList[item.index].children
      this.categoryId = this.secondNavList[item.index].categoryId
      this.teacherMajors.push(this.secondNavList[item.index].categoryId)
      this.teacherNewListData(this.teacherMajors)
    },
    handleThirdNavClick(item) {
      this.categoryId = this.thirdNavList[item.index].categoryId
      this.getCourseList()
    },
    handleNameOrder(orderSeq) {
      this.teacherNewListData(this.teacherMajors, orderSeq)
    },
    handleQuantityOrder(orderSeq) {
      this.teacherNewListData(this.teacherMajors, orderSeq)
    },
    search() {
      this.teacherNewListData([], '', this.keyword)
    },
    toDetail(item) {
      this.$router.push({
        path: '/teacher/detail',
        query: { id: item.teacherId, organizationId: item.organizationId },
      })
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.teacherNewListData([], '', this.keyword)
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.teacherNewListData([], '', this.keyword)
    },
  },
}
</script>
<style lang="scss" scoped>
.course-shop {
  max-width: 1680px;
  // margin: 0 auto;
  width: 100%;
  overflow: hidden;
  .btns {
    text-align: right;
    margin-bottom: 20px;
  }
  .nav {
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 10px;
    ::v-deep.el-tabs {
      .el-tabs__nav-wrap::after {
        display: none;
      }
      .el-tabs__active-bar {
        display: none;
      }
      .el-tabs__header {
        margin: 0;
      }
    }
    ::v-deep.nav-first {
      background: #ffffff;
      height: 60px;
      line-height: 60px;
      padding: 0 10px;
      .el-tabs__item {
        padding: 0 12px;
        height: 40px;
        border-radius: 4px;
        font-size: 14px;
        text-align: center;
        line-height: 40px;
        color: #333333;
        margin: 0 5px;
        transition: all 0.3s;
        &:hover {
          color: #ff7b33;
        }
        &.is-active {
          background: #ff7b33;
          color: #ffffff;
        }
      }
      .el-tabs__header {
        margin-bottom: 10px;
      }
    }
    ::v-deep.nav-second,
    ::v-deep.nav-third {
      padding: 0 20px;
      .el-tabs__nav {
        line-height: 30px;
      }
      .el-tabs__item {
        height: 30px;
      }
    }
  }
  .filter {
    height: 58px;
    display: flex;
    align-items: center;
    padding: 0 0 0 20px;
    ::v-deep.el-dropdown {
      cursor: pointer;
      margin-right: 40px;
    }
    .search {
      margin-left: auto;
      position: relative;
      width: 240px;
      height: 30px;
      background: rgba(0, 0, 0, 0);
      border: 1px solid #ff7b33;
      border-radius: 16px;
      ::v-deep input {
        box-sizing: border-box;
        width: 240px;
        height: 30px;
        border-radius: 16px;
        border: 0;
        background-color: #f5f5f5;
        outline: none;
        padding: 0 60px 0 10px;
      }
      ::v-deep input::-webkit-input-placeholder {
        font-size: 14px;
        color: #909399;
      }
      .btn {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        height: 30px;
        background: #ff7b33;
        border-radius: 15px;
        text-align: center;
        line-height: 30px;
        i {
          font-size: 18px;
          color: #fff;
          line-height: 30px;
        }
      }
    }
  }
  .content {
    box-sizing: border-box;
    background: #f5f5f5;
    .clearfix {
      width: calc(100% + 40px);
    }
    .course-item {
      position: relative;
      cursor: pointer;
      box-sizing: border-box;
      overflow: hidden;
      float: left;
      width: 256px;
      height: 242px;
      margin-bottom: 24px;
      transition: all 0.3s;
      margin-right: 27px;
      &:hover {
        transform: translateY(-5px);
      }
      .teacherWrap {
        box-sizing: border-box;
        // width: 384px;
        background-color: #fff;
        height: 242px;
        overflow: hidden;
        border-radius: 4px;
        padding: 8px;
        box-sizing: border-box;
        .cover {
          position: relative;
          width: 100%;
          height: 140px;
          display: block;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
          img {
            @extend %width-height;
          }
          .isLiveing {
            position: absolute;
            right: 5px;
            bottom: 6px;
            width: 56px;
            height: 22px;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 12px;
            line-height: 22px;
            img {
              width: 10px;
              height: 10px;
              margin-right: 3px;
            }
          }
        }
        .text {
          flex: 1;
          box-sizing: border-box;
          .name {
            padding-top: 10px;
            padding-bottom: 8px;
            .teacherName {
              text-align: center;
              font-size: 16px;
              line-height: 16px;
              font-weight: 500;
              color: #333333;
            }
            .teacherRankStr {
              max-width: 20%;
              padding: 2px 5px;
              opacity: 1;
              border: 1px solid #ff7b33;
              border-radius: 2px;
              font-size: 12px;
              text-align: center;
              color: #ff7b33;
              margin-left: 12px;
            }
          }
          .info {
            font-size: 14px;
            color: #909399;
            margin: 10px 0;
          }
          .teacherMajors {
            text-align: center;
            overflow: hidden;
            span {
              height: 22px;
              line-height: 22px;
              display: inline-block;
              padding: 0 8px;
              margin-right: 10px;
              margin-bottom: 8px;
            }
          }
        }
        .badge {
          z-index: 9;
          position: absolute;
          width: 56px;
          height: 22px;
          left: 10px;
          top: 10px;
          img {
            @extend %width-height;
          }
        }
      }
    }
    .empty {
      text-align: center;
      font-size: 14px;
      padding-bottom: 20px;
      color: rgba(0, 0, 0, 0.25);
    }
  }
  .el-pagination {
    padding-bottom: 20px;
  }
}
</style>
